import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import Layout from '../components/organisms/Layout/Layout';
import PageTitle from '../components/atoms/PageTitle/PageTitle';
import Accordion from '../components/molecules/Accordion/Accordion'
import Container from '../components/atoms/Container/Container';
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs';
import ContentfulContext from '../context/ContentfulProvider';
import { get } from 'lodash';

const ZDSection = ({ data }) => {
    const contentful = useContext(ContentfulContext);
    const translations = get(contentful, 'translationData', {});
    
    const section = (data && data.section) || false;
    const articles = (data && data.articles.nodes) || false;

    // console.log(section, articles);

    return (
        <Layout>
            {section && (
                <>
                    <Helmet title={section.name} />
                    <Container size='medium'>
                        <Breadcrumbs crumbs={[{link: '/support/', label: get(translations, 'support.title', 'Support')}, {label: get(translations, 'faq.title', 'FAQs')}, {label: section.name}]} />
                        <PageTitle title={section.name} />
                        
                        {articles.length > 0 && (
                            <div style={{marginBottom: '80px'}}>
                                {articles.map(article => (
                                    <Accordion key={article.article_id} showIcon={true} endIcon={true} withBackground={true} title={article.title}><div>{parse(article.body)}</div></Accordion>
                                ))}
                            </div>
                        )}
                    </Container>
                </>
            )}
        </Layout>
    );
};

ZDSection.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default ZDSection;

export const query = graphql`
  query ZendeskSectionByID($section_id: Float!) {
    section: zenDeskHelpDeskSections(section_id: { eq: $section_id }) {
        section_id
        name
    }
    articles: allZenDeskHelpDeskArticles(
        filter: {section_id: {eq: $section_id}}
        sort: {fields: position, order: ASC}
      ) {
        nodes {
            article_id
            section_id
            title
            body
            position
        }
      }
  }
`;
